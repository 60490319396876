import React, { useState, useEffect } from 'react';
import logoNextStock from '../img/ns-target-logo.png';
import './CompanyLogos.css';

export default function CompanyLogos({ symbol }) {
    
    const companySymbol = symbol?.replace('.TO', '');

    const baseUrl = `https://firebasestorage.googleapis.com/v0/b/otanertech.firebasestorage.app/o/ns-logos%2F`;
    const [src, setSrc] = useState(`${baseUrl}${companySymbol}.png?alt=media`);
    const [fallbackIndex, setFallbackIndex] = useState(0);

    const fallbackSources = [
        `${baseUrl}${companySymbol}.jpeg?alt=media`,
        `${baseUrl}${companySymbol}.jpg?alt=media`
    ];

    // 🔁 Reset when symbol changes
    useEffect(() => {
        // const companySymbol = symbol?.replace('.TO', '');
        const companySymbol = symbol?.replace(/\..*$/, '');
        setSrc(`${baseUrl}${companySymbol}.png?alt=media`);
        setFallbackIndex(0);
    }, [symbol]);

    const handleError = () => {
        if (fallbackIndex < fallbackSources.length) {
        setSrc(fallbackSources[fallbackIndex]);
        setFallbackIndex(prev => prev + 1);
        } else {
        setSrc(logoNextStock); // fallback image
        }
    };

    return (
        <img
        src={src}
        alt={companySymbol}
        onError={handleError}
        className="company-logos"
        />
    );
}
